import RailsUJS from "@rails/ujs";
if (!window._rails_loaded) {
  RailsUJS.start();
}

import "@/plugins/fslightbox";
import "micromodal/dist/micromodal";

// ---------------------------------------
// Import JS Components
// ---------------------------------------

import Clipboard from "@/helpers/clipboard.js";
import Dropdown from "@/helpers/dropdown.js";
import FlashMessage from "@/helpers/flashMessage.js";
import ItineraryCarousel from '@/modules/Overlands/itineraryCarousel.js';
import ItinerarySlider from "@/modules/Overlands/itinerarySlider.js";
import ItineraryStickyCta from "@/modules/Overlands/itineraryStickyCta.js";
import ItineraryTocNav from "@/modules/Overlands/itineraryTocNav.js";
import MobileDropdown from "@/helpers/mobileDropdown.js";
import VideoOverlay from "@/helpers/videoOverlay.js";
import DropdownMenu from "@/helpers/dropdownMenu.js";
import SignInHelper from "@/helpers/signInHelper.js";
import Accordion from '@/helpers/accordion.js';
import FacebookHelper from '@/helpers/facebookHelper.js';
import HashtagScroll from '@/helpers/hashtagScroll.js';
import OperatorReview from '@/modules/Overlands/operatorReview.js';
import { trackClick, trackDetailView, trackImpression } from '@/utils/eec.js';
import ForgotPassword from '@/helpers/forgotPassword.js';
import SimpleFormVisiblePassword from '@/helpers/simpleFormVisiblePassword.js';
import SeeMoreGuests from '@/helpers/seeMoreGuests.js';
import DraftItinerary from '@/modules/Overlands/draftItinerary.js';
import OpenLink from '@/modules/Overlands/openLink.js';

document.addEventListener("DOMContentLoaded", () => {
  // -------------------------------------
  // Initialize JS Components
  // -------------------------------------

  new Clipboard();
  new Dropdown();
  new FlashMessage();
  new ItineraryCarousel();
  new ItinerarySlider();
  new ItineraryStickyCta();
  new ItineraryTocNav();
  new MobileDropdown();
  new VideoOverlay();
  new DropdownMenu();
  new SignInHelper();
  new Accordion();
  new FacebookHelper();
  new HashtagScroll();
  new OperatorReview();
  new ForgotPassword();
  new SimpleFormVisiblePassword()
  new SeeMoreGuests();
  new DraftItinerary();
  new OpenLink('.itinerary__prelude a');
  MicroModal.init(); // eslint-disable-line no-undef

  // -------------------------------------
  // Refresh FsLightbox
  // -------------------------------------

  if (document.querySelector(".js-itinerary")) {
    refreshFsLightbox(); // eslint-disable-line no-undef
  }

  // setup GA EEC tracking
  document.querySelectorAll("a[data-ga]").forEach(function(em) {
    const data = JSON.parse(em.dataset.ga);
    trackImpression(data);
    em.onclick = function() {
      const data = JSON.parse(this.dataset.ga);
      trackClick(data, this.href) || (window.location = this.href);
      return false;
    }
  })

  const gadetailNode = document.getElementById('gadetail-json');
  if (gadetailNode) {
    const data = JSON.parse(gadetailNode.innerHTML);
    trackDetailView(data);
  }
});
